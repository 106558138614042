export const ALERT_ERROR = 'ALERT_ERROR';
export const ALERT_CLEAR = 'ALERT_WARNING';
export const ALERT_SUCCESS = 'ALERT_SUCCESS';

export const successAlert = (title, message) => {
    return {
        type: ALERT_SUCCESS,
        message: message,
        title: title
    }
}

export const errorAlert = (title, message) => {
    return {
        type: ALERT_ERROR,
        message: message,
        title: title
    }
}

export const clearAlert = () => {
    return {
        type: ALERT_CLEAR,
    }
}