import React from 'react';
import '../../styles/sidebar/exportButton.scss';
import { Button } from 'react-bootstrap';

export const ExportButton = () => {
    return (
        <div className='export-info'>
           <Button className='button-export text-uppercase' variant="secondary">
                Export
                <img src={require('../../images/sidebar/export-icon.svg')} alt='export' />
           </Button>
        </div>
    )
}