import React from 'react';
import '../styles/disabledLoadingButton.scss';
import { Button, Spinner } from 'react-bootstrap'

export const DisabledLoadingButton = () => {
    return (
        <Button className='disabledLoadingButton text-uppercase' variant="secondary" disabled>
            <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
            />
        <span className="loadingText">Loading...</span>
      </Button>
    )
}