import "../styles/signin.css";
import { Formik } from "formik";
import { connect } from "react-redux";
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { history } from "../helpers/history";
import Logo from "../images/moderna_logo.png";
import postLoginAction from "../api/user/postLogin";
import { USER } from "../constants/LocalStorageOptions";
import { Container, Image, Form, Button } from "react-bootstrap";
import { signinValidationScheme } from "../constants/FormValidationSchemas";
import { DisabledLoadingButton } from "../components/disabledLoadingButton";

class SignIn extends Component {
  onSubmit = (email, password) => {
    const { postLogin } = this.props;

    if (email && password) {
      postLogin(email, password);
    }
  };

  componentDidMount() {
    const user = localStorage.getItem(USER);

    if (user) {
      const accessToken = JSON.parse(user).user.access_token;
      if (accessToken) {
        history.push("/dashboard");
      }
    }
  }

  render() {
    return (
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={signinValidationScheme}
      >
        {({ values, errors, handleChange }) => {
          return (
            <Container className="loginContainer">
              <Form>
                <Image className="image mx-auto d-block" src={Logo} />
                <h2 className="subtitle" align="center">
                  Smart Laundry <br />
                  Dashboard
                </h2>
                <Form.Group className="formText">
                  <Form.Text>Log in met uw emailadres en wachtwoord.</Form.Text>
                </Form.Group>
                <Form.Group>
                  <Form.Control
                    id="email"
                    value={values.email}
                    onChange={handleChange}
                    className="minWidth"
                    type="email"
                    placeholder="Voer emailadres in"
                  />
                  <span className="error" style={{ color: "red" }}>
                    {errors.email}
                  </span>
                </Form.Group>
                <Form.Group>
                  <Form.Control
                    id="password"
                    value={values.password}
                    onChange={handleChange}
                    className="minWidth"
                    type="password"
                    placeholder="Voer wachtwoord in"
                  />
                  <span className="error" style={{ color: "red" }}>
                    {errors.password}
                  </span>
                </Form.Group>
                <Form.Group>
                  {this.props.loading ? (
                    <DisabledLoadingButton />
                  ) : (
                    <Button
                      id="login"
                      onClick={() =>
                        this.onSubmit(values.email, values.password)
                      }
                      className="minWidth text-uppercase font-weight-bold"
                      variant="secondary"
                    >
                      Inloggen
                    </Button>
                  )}
                </Form.Group>

                {this.props.error ? (
                  <p id="signin-error" className="text-center text-danger">
                    Controleer uw gebruikersnaam en wachtwoord
                  </p>
                ) : null}
              </Form>
            </Container>
          );
        }}
      </Formik>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.login.loggingIn,
  error: state.login.error,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      postLogin: postLoginAction,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignIn);

export { SignIn };
