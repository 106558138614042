import { Formik } from "formik";
import { connect } from "react-redux";
import '../styles/changepassword.scss';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { Container, Form, Button } from "react-bootstrap";
import changePasswordAction from '../api/user/changePassword';
import { DisabledLoadingButton } from '../components/disabledLoadingButton';
import { passwordValidationSchema } from '../constants/FormValidationSchemas';

class ChangePassword extends Component {

    onSubmit = values => {
        const { changePassword } = this.props;
        changePassword(values.password, values.changePassword)
    }

    render() {
        return (
            <Formik
                initialValues={{
                    password: '',
                    changePassword: ''
                }}
                validationSchema={passwordValidationSchema}
            >
            {({ values, errors, handleChange }) => {
                return (
                    <Container className="changePasswordContainer">
                        <Form className='centerFormGroup'>
                            <h2>Verander uw wachtwoord</h2>
                            <p className='formText'>Het is de eerste keer dat u inlogt. U moet nu eenmalig<br/> uw wachtwoord aanpassen.</p>
                            <Form.Group>
                                <Form.Control id="password" value={values.password} onChange={handleChange} className="minWidth" type="password" placeholder="Voer nieuw wachtwoord in" />
                                <span className="error" style={{ color: "red" }}>
                                    {errors.password}
                                </span>
                            </Form.Group>
                            <Form.Group>
                                <Form.Control id="changePassword" value={values.changePassword} onChange={handleChange} className="minWidth" type="password" placeholder="Voer nieuw wachtwoord in" />
                                <span className="error" style={{ color: "red" }}>
                                    {errors.changePassword}
                                </span>
                            </Form.Group>
                            <Form.Group className='centerFormGroup'>
                            {this.props.loading ?

                                <DisabledLoadingButton />
                                :
                                <Button id="submitPasswordChange" onClick={() => this.onSubmit(values)} className="minWidth text-uppercase font-weight-bold" variant="secondary">Verander wachtwoord</Button>
                            }
                            </Form.Group>
                        </Form>
                    </Container>
                );
            }}
            </Formik>
        )
    }
}

const mapStateToProps = state => ({
    loading: state.changePassword.changingPassword
})

const mapDispatchToProps = dispatch => bindActionCreators({
    changePassword: changePasswordAction
}, dispatch)

export default connect (
    mapStateToProps,
    mapDispatchToProps
)(ChangePassword);

export { ChangePassword }