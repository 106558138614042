import { ALERT_ERROR, ALERT_SUCCESS, ALERT_CLEAR } from './alertActions';

export const initialState = {
   type: null,
   title: null,
   message: null
}

export default function alert(state = initialState, action) {
    switch (action.type) {
        case ALERT_ERROR:
            return {
                type: 'danger',
                title: action.title,
                message: action.message
            }
        case ALERT_SUCCESS:
            return {
                type: 'success',
                title: action.title,
                message: action.message
            }
        case ALERT_CLEAR: 
            return {
                type: null,
                title: null,
                message: null
            }
        default:
            return state;
    }
}