import {
  fetchContainersPending,
  fetchContainerSuccess,
  fetchContainersError,
} from "../redux/container/containerActions";
import { getBaseURL } from "../api/ApiBase";

const fetchContainers = () => {
  return (dispatch) => {
    dispatch(fetchContainersPending());
    return fetch(`${getBaseURL()}/containers`)
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(fetchContainerSuccess(res));
      })
      .catch((error) => {
        dispatch(fetchContainersError(error));
      });
  };
};

export default fetchContainers;
