import { connect } from "react-redux";
import React, { Component } from "react";
import { StatusInfo } from './statusInfo';
import '../../styles/sidebar/sidebar.scss';
import { WarningInfo } from './warningInfo';
import { LoadingInfo } from './loadingInfo';
import { LogbookInfo } from './logbookInfo';
import ContainerList from './containerList';
import { ExportButton } from './exportButton';

class Sidebar extends Component {
    render() {
        return (
            <div className={this.props.filterbarActive ? 'main-sidebar-filter-active' : 'main-sidebar'}>
                {this.props.containerCluster && this.props.containerCluster.length > 1 ?
                    <ContainerList containers={this.props.containerCluster}/> 
                    
                    : 
                    
                    null
                }
                <div className="sidebar">
                    <div className="inner-padding">
                        <div className="header">
                            Container #{this.props.container.properties.id}
                        </div>
                        <WarningInfo container={this.props.container} />
                        <hr className="underline" />
                        <LoadingInfo container={this.props.container} />
                        <hr className="underline" />
                        <StatusInfo container={this.props.container} />
                        <hr className="underline" />
                        <LogbookInfo />
                    </div>
                    <ExportButton />
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    containers: state.containers.containers,
    filterbarActive: state.navigation.filter,
    container: state.containers.selectedContainer,
    containerCluster: state.containers.selectedContainerCluster
})

export default connect(
    mapStateToProps,
    null
)(Sidebar)

export { Sidebar }