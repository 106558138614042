import { combineReducers } from "redux";

import alert from './alert/alertReducer';
import login from './login/loginReducer';
import places from './place/placeReducer';
import search from './search/searchReducer';
import filter from './filter/filterReducer';
import warning from './warning/warningReducer';
import containers from './container/containerReducer'
import navigation from './navigation/navigationReducer';
import changePassword from './login/changePasswordReducer';

export default combineReducers({
    alert,
    login,
    places,
    search,
    filter,
    warning,
    containers,
    navigation,
    changePassword
});