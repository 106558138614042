export const LOCATION = 'locationOptions';
export const DESTINATION = 'destinationOptions';
export const WARNING = 'warningOptions';
export const CONTENT = 'contentOptions';
export const LOAD_MIN = 'loadOptionsMin';
export const LOAD_MAX = 'loadOptionsMax';

const filterOptions = {
    [LOCATION] : [
        { value: 'at_moderna', label: 'Bij Moderna' },
        { value: 'arrived', label: 'Bij klant' },
        { value: 'at_hub', label: 'Bij hub' },
        { value: 'transit', label: 'Onderweg' },
        { value: 'conflicting', label: 'Afwijkend' }
    ],

    [DESTINATION]: [
        { value: 'without', label: 'Zonder bestemming' },
        { value: 'customer', label: 'Naar klant' },
        { value: 'moderna', label: 'Naar Moderna' },
        { value: 'hub', label: 'Naar hub' }
    ],

    [WARNING]: [
        { value: 'without', label: 'Zonder waarschuwing' },
        { value: 'battery_low', label: 'Lage batterij' },
        { value: 'no_signal', label: 'Geen signaal' },
        { value: 'conflicting_location', label: 'Locatiegerelateerd' },
        { value: 'stay_duration_exceeded', label: 'Tijdgerelateerd' }
    ],

    [CONTENT]: [
        { value: 'linnen', label: 'Linnengoed' },
        { value: 'drycleaning', label: 'Stomerijgoed' },
        { value: 'uniforms', label: 'Werkkleding' },
        { value: 'merchandise', label: 'Handel'}
    ],

    [LOAD_MIN]: 
        {value: 0, label: 'min'},
    

    [LOAD_MAX]:
        {value: 100, label: 'max'}
    
}

//Export a const that can be used within different components
export const FILTER_OPTIONS = filterOptions;