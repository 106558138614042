
export const FETCH_WARNINGS_PENDING = 'FETCH_WARNINGS_PENDING';
export const FETCH_WARNINGS_SUCCESS = 'FETCH_WARNINGS_SUCCESS';
export const FETCH_WARNINGS_ERROR = 'FETCH_WARNINGS_ERROR';

export const TOGGLE_WARNING_DISPLAY = 'TOGGLE_WARNING_DISPLAY';

export const fetchWarningsPending = () => {
    return {
        type: FETCH_WARNINGS_PENDING
    }
}

export const fetchWarningsSuccess = warnings => {
    return {
        type: FETCH_WARNINGS_SUCCESS,
        warnings: warnings
    }
}

export const fetchWarningsError = error => {
    return {
        type: FETCH_WARNINGS_ERROR,
        error: error
    }
}

export const toggleWarningDisplay = show => {
    return {
        type: TOGGLE_WARNING_DISPLAY,
    }
}