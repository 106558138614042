import { 
    FETCH_CONTAINERS_ERROR,
    FETCH_CONTAINERS_PENDING,
    FETCH_CONTAINERS_SUCCESS,
    UPDATE_CONTAINER_SELECTED,
    SELECT_CONTAINER_IN_CLUSTER,
    DESELECT_CONTAINER_AND_CLUSTER,
    UPDATE_CONTAINER_CLUSTER_SELECTED
} from './containerActions';

export const initialState = {
    selectedContainerCluster: [],
    selectedContainer: null,
    containers: null,
    loading: true,
    error: null
};

export default function containerReducer(state = initialState, action) {
    switch(action.type) {
        case FETCH_CONTAINERS_PENDING:
            return {
                ...state,
                loading: true,
                error: null
            };

        case FETCH_CONTAINERS_SUCCESS:
            return {
                ...state,
                loading: false,
                containers: action.containers,
                error: null
            };
        case FETCH_CONTAINERS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
                containers: null
            };
        case UPDATE_CONTAINER_SELECTED:
            return {
                ...state,
                selectedContainer: action.container,
                selectedContainerCluster: []
            }
        case UPDATE_CONTAINER_CLUSTER_SELECTED:
            return {
                ...state,
                selectedContainer: action.containers[0],
                selectedContainerCluster: action.containers
            }
        case SELECT_CONTAINER_IN_CLUSTER:
            return {
                ...state,
                selectedContainer: action.container
            }
        case DESELECT_CONTAINER_AND_CLUSTER:
            return {
                ...state,
                selectedContainer: null,
                selectedContainerCluster: []
            }
        default:
            return state;
    }
}

export const getContainers = state => state.containers;
export const getContainersPending = state => state.loading;
export const getContainersError = state => state.error;