import React from 'react';
import '../../styles/warninginfo.scss';
import { EmptyList } from '../emptyList';
import { TRANSLATION_HELPER, DUTCH } from '../../helpers/translationHelper';

export const WarningInfo = ({container}) => {
    //For some strange reason the resulting object of a feature or a cluster is different due to Mapbox.
    //In order to handle both situations we check if it is an array first
    const warnings = Array.isArray(container.properties.warnings) ? container.properties.warnings : JSON.parse(container.properties.warnings)
    
    return (
        <div id='warning-info'>
            {warnings.length > 0 ?
                warnings.map((warning, index) => {
                    return(
                        <div key={warning.id} className="row-warning">
                            <img src={require("../../images/warning.png")} className="warning-image" alt="warning icon"/>
                            <div className='warning-text'>
                                <span className="title">
                                    {
                                        TRANSLATION_HELPER.getTranslationForWarningTitle(warning.type, DUTCH)
                                    }
                                </span>
                            </div>
                        </div>
                    )
                })
    
                :

                <div className='row-no-warning'>
                    <img src={require('../../images/sidebar/check.svg')} className={"no-warning-image"} alt='warning'/>
                    <EmptyList message='Geen waarschuwingen'/>
                </div>
            }
        </div>
    )
}