import { USER } from "../constants/LocalStorageOptions";

export const getBaseURL = () => {
  if (
    process.env.NODE_ENV === "development" ||
    process.env.NODE_ENV === "test"
  ) {
    return "http://moderna.test/api";
  } else if (process.env.NODE_ENV === "production") {
    return "https://moderna-api.devcake.app/api";
  }
};

export const getAuthHeader = () => {
  let user = JSON.parse(localStorage.getItem(USER));

  if (user && user.user.access_token) {
    return {
      "Content-type": "application/json",
      Authorization: "Bearer " + user.user.access_token,
    };
  } else {
    return {
      "Content-type": "application/json",
    };
  }
};

export const handleResponse = (response) => {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
};
