import * as Yup from "yup";

export const passwordValidationSchema = Yup.object().shape({
    password: Yup.string().required("Dit is een verplicht veld."),
    changePassword: Yup.string().when("password", {
        is: val => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
            [Yup.ref("password")],
            "Beide wachtwoorden moeten hetzelfde zijn."
        )
    })
});

export const signinValidationScheme = Yup.object().shape({
    email: Yup.string().required("Dit is een verplicht veld."),
    password: Yup.string().required("Dit is een verplicht veld.")
})

  