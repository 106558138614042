import { connect } from "react-redux";
import React, {Component} from 'react';
import { EmptyList } from '../emptyList';
import '../../styles/warning/warning.scss';
import { WarningItem } from './warningItem';
import MinimizeButton from './minimizeButton';
import { ExportButton } from './exportButton';

class Warning extends Component {

    render() {
        return (
            <div className='main-warning'>
                <MinimizeButton/>
                <div className='export-warning'>
                    <ExportButton/>
                </div>
                <div className='inner-padding'>
                    <div className='warning'>                
                        { this.props.warnings && this.props.warnings.length > 0 ? this.props.warnings.map((item) => 
                            <div key={item.id}>
                                <WarningItem warning={item} />
                                <div className='underline'/>
                            </div>
                        ) : 
                            
                            <EmptyList message='Op dit moment zijn er geen containers met waarschuwingen.'/>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    //Props to map from the container reducer
    warnings: state.warning.warnings,
})

export default connect (
    mapStateToProps,
    null
)(Warning)

export {  Warning }