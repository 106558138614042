import {
    FETCH_PLACES_PENDING,
    FETCH_PLACES_SUCCESS,
    FETCH_PLACES_ERROR
} from './placeActions';

export const initialState = {
    places: null,
    loading: true,
    error: null
};

export default function placeReducer(state = initialState, action) {
    switch(action.type) {
        case FETCH_PLACES_PENDING:
            return {
                ...state,
                loading: true,
                error: null
            };

        case FETCH_PLACES_SUCCESS:
            return {
                ...state,
                loading: false,
                places: action.places,
                error: null
            };

        case FETCH_PLACES_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
                places: null
            };

        default:
            return state;
    }
}

export const getPlaces = state => state.places;
export const getPlacesPending = state => state.loading;
export const getPlacesError = state => state.error;