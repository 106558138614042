import React from 'react';
import { Image }  from 'react-bootstrap';
import '../../styles/customer/title.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Title = ({icon, title, fontawesome}) => {
    return (
        <div className={'customRow'}>
            {fontawesome ?

                <FontAwesomeIcon icon={icon} size={'3x'} />

                :

                <Image src={icon} style={{width: 50, height: 50}} />
            }
            
            <h3 className={'ml-3 font-weight-bold'}>{title}</h3>
        </div>
    )
}

export default Title