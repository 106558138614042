import { connect } from "react-redux";
import React, { Component } from "react";
import "../../styles/navigationbar.css";
import { bindActionCreators } from "redux";
import WarningButton from "./warningButton";
import SearchResults from "../Search/SearchResults";
import { USER } from "../../constants/LocalStorageOptions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LOCAL_STORAGE_HELPER } from "../../helpers/localStorageHelper";
import { toggleFilterNavigation } from "../../redux/navigation/navigationActions";
import {
  Navbar,
  Nav,
  Form,
  Button,
  ToggleButton,
  ToggleButtonGroup,
  NavDropdown,
} from "react-bootstrap";
import handleLogout from "../../api/user/handleLogout";

class NavigationBar extends Component {
  handleFilterChange = (e) => {
    const { toggleFilter } = this.props;
    toggleFilter(e.target.checked);
  };

  render() {
    return (
      <div>
        <Navbar bg="primary" variant="dark">
          <Navbar.Brand>
            <div className="logoContainer">
              <img
                src={require("../../images/logo.png")}
                className="logo"
                alt="Moderna"
              />
            </div>
          </Navbar.Brand>
          <Navbar.Collapse>
            <Nav defaultActiveKey="/dashboard">
              <Nav.Item>
                <Nav.Link className="nav-cog" href="/dashboard">
                  Kaart
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="nav-cog" eventKey="containers">
                  Containers
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="nav-cog" eventKey="customers">
                  Klanten
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="nav-cog" eventKey="hubs">
                  Hubs
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Form inline className="ml-4">
              <SearchResults />
              <Button className="ml-4" variant="outline-light">
                Zoek
              </Button>
            </Form>
            {window.location.pathname === "/dashboard" ? (
              <div className="mr-auto">
                <ToggleButtonGroup className="mr-auto" type="checkbox">
                  <ToggleButton
                    onChange={this.handleFilterChange}
                    className="ml-4 mr-4 activeColor"
                    variant="outline-light"
                  >
                    <FontAwesomeIcon
                      className="mr-1"
                      color="white"
                      icon="filter"
                    />
                    Filter kaart
                  </ToggleButton>
                </ToggleButtonGroup>
                <WarningButton />
              </div>
            ) : (
              <div className="mr-auto" />
            )}
            <Navbar.Text className="text-white">
              Welkom, {LOCAL_STORAGE_HELPER.getFromLocalStorage(USER).user.name}
            </Navbar.Text>
            <NavDropdown
              alignRight
              title={
                <FontAwesomeIcon
                  style={{ fontSize: 22 }}
                  color="white"
                  icon="cog"
                />
              }
            >
              <NavDropdown.Item onClick={() => handleLogout()}>
                Logout
              </NavDropdown.Item>
            </NavDropdown>
          </Navbar.Collapse>
        </Navbar>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      toggleFilter: toggleFilterNavigation,
    },
    dispatch
  );

export default connect(
  null,
  mapDispatchToProps
)(NavigationBar);

export { NavigationBar };
