import '../../styles/filterbar.css';
import { connect } from "react-redux";
import React, {Component} from "react";
import { bindActionCreators } from 'redux';
import FilterMultiSelect from './filterMultiSelect';
import { Form, Row, Col, Container, InputGroup } from "react-bootstrap";
import { updateFilterSettings } from '../../redux/filter/filterActions';
import {FILTER_OPTIONS, LOAD_MIN, LOAD_MAX, LOCATION, DESTINATION, WARNING, CONTENT} from "../../constants/FilterOptions" 

var min = 0;
var max = 100;

class FilterBar extends Component {

    handleChangeMin = (event) => {
        let { updateFilter } = this.props;
        min = event.target.value;
        let selectedOption = {value: min, label: 'min' }

        updateFilter( selectedOption, LOAD_MIN );
    };

    handleChangeMax = (event) => {
        let { updateFilter } = this.props;
        max = event.target.value;
        let selectedOption = {value: max, label: 'max' }

        updateFilter( selectedOption, LOAD_MAX );
    };

    render() {
        return(
            <Container className="containerBackground" fluid>
                <Row className="marginTop">
                    <Col xl ="8">
                        <Row>
                            <Col xl="3">
                                <Form.Label className="text-white font-weight-bold responsiveText">Filter containers op locatie</Form.Label>
                            </Col>
                            <Col xl="3">
                                <Form.Label className="text-white font-weight-bold responsiveText">Filter containers op bestemming</Form.Label>
                            </Col>
                            <Col xl="3">
                                <Form.Label className="text-white font-weight-bold responsiveText">Filter containers op waarschuwing</Form.Label>
                            </Col>
                            <Col xl="3">
                                <Form.Label className="text-white font-weight-bold responsiveText">Filter containers op inhoud</Form.Label>
                            </Col>
                        </Row>
                    </Col>
                    <Col xl ="4">
                        <Form>
                            <Form.Label className="text-white font-weight-bold responsiveText"><strong>Filter containers op beladingsgraad</strong></Form.Label>
                        </Form>
                    </Col>

                </Row>
                

                <Row>
                    <Col xl ="8">
                       <Row>
                        <Col xl="3">
                                <FilterMultiSelect type={LOCATION} data={FILTER_OPTIONS[LOCATION]} placeholder="Alle locaties"/>
                            </Col>
                            <Col xl="3">
                                <FilterMultiSelect type={DESTINATION} data={FILTER_OPTIONS[DESTINATION]} placeholder="Alle bestemmingen"/>
                            </Col>
                            <Col xl="3">
                                <FilterMultiSelect type={WARNING} data={FILTER_OPTIONS[WARNING]} placeholder="Met en zonder waarschuwing"/>
                            </Col>
                            <Col xl="3">
                                <FilterMultiSelect type={CONTENT} data={FILTER_OPTIONS[CONTENT]} placeholder="Alle types inhoud"/>
                            </Col>
                       </Row>
                    </Col>
                    <Col lg="4">
                        <Form.Group as={Row}>
                            <Col lg={6}>
                                <Form.Group as={Row}>
                                    <Form.Label column lg={3} className="text-white">
                                        min
                                    </Form.Label>
                                    <Col lg={9}>
                                        <InputGroup className="customHeight">
                                            <Form.Control type="number" placeholder={0} value={min} className="customWidth" onChange={this.handleChangeMin}/>
                                            <InputGroup.Append>
                                                <InputGroup.Text>
                                                    %
                                                </InputGroup.Text>
                                            </InputGroup.Append>
                                        </InputGroup>
                                    </Col>
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group as={Row}>
                                    <Form.Label column lg={3} className="text-white">
                                        max
                                    </Form.Label>
                                    <Col lg={9}>
                                        <InputGroup>
                                            <Form.Control type="number" placeholder={100} value={max} className="customWidth" onChange={this.handleChangeMax}/>
                                            <InputGroup.Append>
                                                <InputGroup.Text>
                                                    %
                                                </InputGroup.Text>
                                            </InputGroup.Append>
                                        </InputGroup>
                                    </Col>
                                </Form.Group>
                            </Col>
                        </Form.Group>
                    </Col>
                </Row>
            </Container>
        )
    }
}

const mapStateToProps = state => ({
    currentValue: state.filter,
})

const mapDispatchToProps = dispatch => bindActionCreators({
    updateFilter: updateFilterSettings
}, dispatch)

export default connect (
    mapStateToProps,
    mapDispatchToProps
)(FilterBar)

export {FilterBar};