import React from 'react';
import '../../styles/warning/warningItem.scss';
import { DATE_HELPER } from '../../helpers/dateHelper';
import { TRANSLATION_HELPER, DUTCH } from '../../helpers/translationHelper';
//Warning description imports
import NoSignalWarning from './WarningDescriptions/noSignalWarning';
import BatteryLowWarning from './WarningDescriptions/batteryLowWarning';
import MerchantContainerWarning from './WarningDescriptions/merchantContainerWarning';
import ConflictingLocationWarning from './WarningDescriptions/conflictingLocationWarning';
import StayDurationExceededWarning from './WarningDescriptions/stayDurationExceededWarning';

export const WarningItem = props => {
    const warning = props.warning

    return (
        <div className='warning-item'>
            <div className='header'>
                {
                    TRANSLATION_HELPER.getTranslationForWarningTitle(warning.type, DUTCH)
                }
            </div>
            <div className='date'>
                {
                    DATE_HELPER.getDateToDisplay(warning.created_at)
                }
            </div>
            {   warning.type === 'battery_low' ?

                    <BatteryLowWarning 
                        warningType={warning.type}
                        containerID={3} 
                        battery_level={warning.device_history.battery_level} />

                :

                warning.type === 'no_signal' ?

                    <NoSignalWarning
                        warningType={warning.type}
                        customerName={warning.place.name}
                        containerID={warning.container_history.container_id}/>

                :

                warning.type === 'conflicting_location' ?

                    <ConflictingLocationWarning
                        warningType={warning.type}
                        customerName={'Hoofdkantoor Moderna'}
                        containerID={5}/>

                :

                warning.type === 'stay_duration_exceeded' ?

                    <StayDurationExceededWarning
                        warningType={warning.type}
                        customerName={warning.place.name}
                        containerID={warning.container_history.container_id} />

                :

                warning.type === 'merch_at_customer' ?

                    <MerchantContainerWarning
                        warningType={warning.type}
                        customerName={warning.place.name}
                        containerID={warning.container_history.container_id} />

                : 

                    null
                
            }                
        </div>
    )
}