import React from 'react';
import { ICON_HELPER } from '../../helpers/iconHelper';
import '../../styles/sidebar/statusInfo.scss';

export const StatusInfo = ({ container }) => {

    const determineDataType = (value) => {
        const temp = Number(value);
        if (isNaN(temp)) {
            return 0;
        }

        return value;
    }

    return (
        <div className='status-info'>
            <div className='load-factor-info'>
                <img src={ICON_HELPER.getLoadIcon(Math.round(determineDataType(container.properties.load_percentage)))} alt='load-percentage' />
                <span className='load-factor text-secondary font-weight-bold'>{determineDataType(container.properties.load_percentage)} %</span>
                <span className='sub-text'>Vulgraad</span>
            </div>
            <div className='battery-info'>
                <img src={ICON_HELPER.getBatteryIcon(Math.round(determineDataType(container.properties.battery_level)))} alt='battery' />
                <span className='battery text-secondary font-weight-bold'>{Math.round(determineDataType(container.properties.battery_level))} %</span>
                <span className='sub-text'>Batterij</span>
            </div>
        </div>
    )
}