import React from 'react';
import { Image } from 'react-bootstrap';
import '../../styles/customer/customerheader.scss';

const Header = ({name}) => {
    return (
        <div className={'customRow align-middle'}>
            <h2 className={'font-weight-bolder mr-auto'}>{name}</h2>
            
            <Image
                fluid
                src={require('../../images/customer/logo-krim-texel.png')}/>
        </div>
    )
}

export default Header