import featureCollection from "turf-featurecollection";

class GeoJSONHelper {
  parseFeaturesToFeatureCollection = (features) => {
    if (!features) return featureCollection([]);

    let collection = featureCollection(features);
    return collection;
  };

  parseFeatureCollectionToFeaturesArray(featureCollection) {
    if (!featureCollection) return [];

    let features = featureCollection.features;
    return features;
  }

  filterFeaturesByStatus = (features, status) => {
    if (features) {
      let filteredFeatures = features.filter(
        (feature) => feature.properties.status === status
      );
      filteredFeatures = this.parseFeaturesToFeatureCollection(
        filteredFeatures
      );

      return filteredFeatures;
    }
  };
}

//for singleton pattern
const GEOJSON_HELPER_INSTANCE = new GeoJSONHelper();
Object.freeze(GEOJSON_HELPER_INSTANCE);

export { GEOJSON_HELPER_INSTANCE };
