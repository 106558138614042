import "../styles/map.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { history } from "../helpers/history";
import { MARKER_IMAGES } from "../constants/MapImages";
import fetchContainersAction from "../api/fetchContainers";
import { MAP_CLICK_HELPER } from "../helpers/mapClickHelper";
import { FILTER_HELPER_INSTANCE } from "../helpers/filterHelper";
import { GEOJSON_HELPER_INSTANCE } from "../helpers/geoJSONHelper";
import ReactMapboxGl, { Layer, GeoJSONLayer } from "react-mapbox-gl";
import {
  GEOJSON_SOURCE_OPTIONS,
  LAYER_LAYOUT_OPTIONS,
} from "../constants/MapBoxOptions";
import {
  updateContainerSelected,
  updateContainerClusterSelected,
  fetchContainerSuccess,
} from "../redux/container/containerActions";
import {
  getContainersError,
  getContainers,
  getContainersPending,
} from "../redux/container/containerReducer";
import Pusher from "pusher-js";
import { echo } from "../constants/Echo";

const Mapbox = ReactMapboxGl({
  accessToken:
    "pk.eyJ1IjoibWlrZXNsaW5rbWFuIiwiYSI6ImNqOXNpZG03MzZidzIzM3FxZjZtOGtrdzcifQ.LmE1W2DxSDkIy5zDoC5F9Q",
  renderWorldCopies: false,
});

class Map extends Component {
  constructor(props) {
    super(props);

    history.listen((location) => {
      if (location.search) {
        let qParams = location.search.split("?")[1];
        let parameters = qParams.split("&");

        let lat = parameters[0].substr(4);
        let lon = parameters[1].substr(4);

        this.setState({
          center: [lon, lat],
          zoom: 15,
        });
      }
    });
  }

  state = {
    zoom: 7,
    center: [5.96944, 52.21],
  };

  componentDidMount() {
    const { fetchContainers } = this.props;
    fetchContainers();

    echo.channel("containers").listen(".location-update", (data) => {
      const feature = JSON.parse(data.feature);
      const featureToUpdate = this.props.containers.features.filter(
        (element) => element.properties.id === feature.properties.id
      );
      const remainingFeatures = this.props.containers.features.filter(
        (element) => element.properties.id !== feature.properties.id
      );

      featureToUpdate[0] = feature;

      const containerArray = GEOJSON_HELPER_INSTANCE.parseFeaturesToFeatureCollection(
        featureToUpdate.concat(remainingFeatures)
      );
      this.props.updateContainers(containerArray);

      if (this.props.selectedContainer) {
        this.props.selectContainer(feature);
      }
    });
  }

  componentWillUnmount() {
    echo.channel("containers").stopListening(".location-update");
  }

  onZoomEnd = (map) => {
    let zoom = map.getZoom();
    this.setState({
      zoom,
    });
  };

  onSymbolClick = async (event) => {
    const options = {
      point: event.point,
      map: event.target,
    };

    const features = await MAP_CLICK_HELPER.getFeaturesForSymbol(options);
    if (Array.isArray(features)) {
      this.props.selectContainerCluster(features);
      MAP_CLICK_HELPER.setSelectedContainerActive(features[0]);
    } else {
      features.properties.place = JSON.parse(features.properties.place);
      this.props.selectContainer(features);
    }
  };

  onDragEnd = (map) => {
    let center = map.getCenter();
    this.setState({
      center: [center.lng, center.lat],
    });
  };

  onMapClick = (map) => {
    this.props.selectContainer(null);
  };

  render() {
    let containerFeatures =
      this.props.loadingContainers || this.props.errorContainers
        ? null
        : this.props.containers.features;

    //First we filter all containers
    containerFeatures = FILTER_HELPER_INSTANCE.filterFeatures(
      containerFeatures,
      this.props.filterValues
    );

    let hubContainers = GEOJSON_HELPER_INSTANCE.filterFeaturesByStatus(
      containerFeatures,
      "at_hub"
    );
    let arrivedContainers = GEOJSON_HELPER_INSTANCE.filterFeaturesByStatus(
      containerFeatures,
      "arrived"
    );
    let transitContainers = GEOJSON_HELPER_INSTANCE.filterFeaturesByStatus(
      containerFeatures,
      "transit"
    );
    let modernaContainers = GEOJSON_HELPER_INSTANCE.filterFeaturesByStatus(
      containerFeatures,
      "at_moderna"
    );
    let conflictingContainers = GEOJSON_HELPER_INSTANCE.filterFeaturesByStatus(
      containerFeatures,
      "conflicting"
    );

    return (
      <div id="mapContainer" className="mapContainer">
        <Mapbox
          zoom={[this.state.zoom]}
          center={this.state.center}
          maxBounds={[-180, -85, 180, 85]}
          onClick={(map) => this.onMapClick(map)}
          onDragEnd={(map) => this.onDragEnd(map)}
          onZoomEnd={(map) => this.onZoomEnd(map)}
          style="mapbox://styles/mapbox/streets-v8" // eslint-disable-line react/style-prop-object
          containerStyle={{ height: "100%", width: "100%" }}
        >
          {//Determine if we are still fetching containers from the API. If we are return null
            this.props.loadingContainers || this.props.errorContainers ? null : (
              <div id="mapLayers">
                <GeoJSONLayer
                  id="arrived_id"
                  data={arrivedContainers}
                  sourceOptions={{ ...GEOJSON_SOURCE_OPTIONS }}
                  symbolOnClick={(evt) => this.onSymbolClick(evt)}
                  symbolLayout={
                    this.props.warningVisible
                      ? {
                        "icon-image": "warningCluster",
                        "text-offset": [1.7, -4.3],
                        ...LAYER_LAYOUT_OPTIONS,
                      }
                      : {
                        "icon-image": "arrivedCluster",
                        "text-offset": [1.7, -4.3],
                        ...LAYER_LAYOUT_OPTIONS,
                      }
                  }
                />

                <GeoJSONLayer
                  id="transit_id"
                  data={transitContainers}
                  sourceOptions={{ ...GEOJSON_SOURCE_OPTIONS }}
                  symbolOnClick={(evt) => this.onSymbolClick(evt)}
                  symbolLayout={
                    this.props.warningVisible
                      ? {
                        "icon-image": "warningCluster",
                        "text-offset": [1.7, -4.3],
                        ...LAYER_LAYOUT_OPTIONS,
                      }
                      : {
                        "icon-image": "transitCluster",
                        "text-offset": [1.7, -4.3],
                        ...LAYER_LAYOUT_OPTIONS,
                      }
                  }
                />

                <GeoJSONLayer
                  id="hub_id"
                  data={hubContainers}
                  sourceOptions={{ ...GEOJSON_SOURCE_OPTIONS }}
                  symbolOnClick={(evt) => this.onSymbolClick(evt)}
                  symbolLayout={
                    this.props.warningVisible
                      ? {
                        "icon-image": "warningCluster",
                        "text-offset": [1.7, -4.3],
                        ...LAYER_LAYOUT_OPTIONS,
                      }
                      : {
                        "icon-image": "hubCluster",
                        "text-offset": [1.7, -4.6],
                        ...LAYER_LAYOUT_OPTIONS,
                      }
                  }
                />

                <GeoJSONLayer
                  id="moderna_id"
                  data={modernaContainers}
                  sourceOptions={{ ...GEOJSON_SOURCE_OPTIONS }}
                  symbolOnClick={(evt) => this.onSymbolClick(evt)}
                  symbolLayout={
                    this.props.warningVisible
                      ? {
                        "icon-image": "warningCluster",
                        "text-offset": [1.7, -4.3],
                        ...LAYER_LAYOUT_OPTIONS,
                      }
                      : {
                        "icon-image": "modernaCluster",
                        "text-offset": [1.7, -4.6],
                        ...LAYER_LAYOUT_OPTIONS,
                      }
                  }
                />

                <GeoJSONLayer
                  id="conflicting_id"
                  data={conflictingContainers}
                  sourceOptions={{ ...GEOJSON_SOURCE_OPTIONS }}
                  symbolOnClick={(evt) => this.onSymbolClick(evt)}
                  symbolLayout={
                    this.props.warningVisible
                      ? {
                        "icon-image": "warningRedCluster",
                        "text-offset": [1.7, -4.3],
                        ...LAYER_LAYOUT_OPTIONS,
                      }
                      : {
                        "icon-image": "transitCluster",
                        "text-offset": [1.7, -4.3],
                        ...LAYER_LAYOUT_OPTIONS,
                      }
                  }
                />

                <Layer images={MARKER_IMAGES} />
              </div>
            )}
        </Mapbox>
      </div>
    );
  }
}

/**
 * Remember to always include the reducer
 * from which you're trying to get the state
 *  Here we have two (Places & Containers)
 */
const mapStateToProps = (state) => ({
  //Props to map from the container reducer
  containers: getContainers(state.containers),
  selectedContainer: state.containers.selectedContainer,
  errorContainers: getContainersError(state.containers),
  loadingContainers: getContainersPending(state.containers),
  //Props to map from the filter reducer
  filterValues: state.filter,
  warningVisible: state.warning.show,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateContainers: fetchContainerSuccess,
      fetchContainers: fetchContainersAction,
      selectContainer: updateContainerSelected,
      selectContainerCluster: updateContainerClusterSelected,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Map);

export { Map };
