import { CHANGE_PASSWORD_PENDING, CHANGE_PASSWORD_SUCCESS, CHANGE_PASSWORD_ERROR } from './changePasswordActions';

export const initialState = {
    changingPassword: false,
    message: null,
    error: null
}

export default function changePasswordReducer(state = initialState, action) {
    switch(action.type) {
        case CHANGE_PASSWORD_PENDING:
            return {
                ...state,
                changingPassword: true
            };
        case CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                changingPassword: false,
                message: action.message
            };
        case CHANGE_PASSWORD_ERROR:
            return {
                ...state,
                changingPassword: false,
                error: action.error
            };
        default:
            return state;
    }
}