import React from 'react';
import '../../styles/sidebar/loadingInfo.scss';
import {TRANSLATION_HELPER, DUTCH} from '../../helpers/translationHelper';

export const LoadingInfo = ({container}) => {
    let reachedKey = container.properties.reached ? 'arrived' : 'transit';
    return (
        <div className='load-info'>
            <div className="row-load">
                <img src={require('../../images/sidebar/building.svg')} className='load-image' alt='load'/>
                <div className='load-text'>
                    {container.properties.place ? (
                        <span className='title'>
                            {TRANSLATION_HELPER.getTranslationForReached(reachedKey, DUTCH)} <span
                            className='text-secondary'>{container.properties.place.name}</span>
                        </span>
                    ) : (
                        <span className='title'>{TRANSLATION_HELPER.getTranslationForReached('no_place', DUTCH)}</span>
                    )}
                </div>
            </div>
            <div className='row-load'>
                <img src={require('../../images/sidebar/clothes.svg')} className='load-image' alt='clothes'/>
                <div className='load-text'>
                    <span className='title'>
                        Vervoert {TRANSLATION_HELPER.getTranslationForCargoType(container.properties.type, DUTCH)}
                    </span>
                </div>
            </div>
        </div>
    )
}