import React from 'react';
import '../../styles/customer/logbookentries.scss'

export const LogbookEntries = () => {
    return (
        <div className='logbook-info'>
            <div className='logbook-status'>
                <div className='logbook-title'>Vertrokken bij Moderna</div>
                <div className='text-secondary logbook-title'>Hoofdkantoor Hardenberg</div>
                <div className='text-muted logbook-title'>2023-01-03 18:50 (ongeveer 1 maand geleden)</div>
            </div>
            <div className='logbook-connector'>
                <img src={require('../../images/sidebar/logbook-connector.svg')} alt='connector' />
            </div>
            <div className='logbook-status'>
                <div className='logbook-title'>Aangekomen bij Krim</div>
                <div className='text-secondary logbook-title'>Vakantiepark de Krim</div>
                <div className='text-muted logbook-title'>2023-01-04 00:48 (ongeveer 1 maand geleden')</div>
            </div>
            <div className='logbook-connector'>
                <img src={require('../../images/sidebar/logbook-connector.svg')} alt='connector' />
            </div>
            <div className='logbook-status'>
                <div className='logbook-title'>Vertrokken bij Krim</div>
                <div className='text-secondary logbook-title'>Vakantiepark de Krim</div>
                <div className='text-muted logbook-title'>2023-01-08 13:48 (ongeveer 1 maand geleden)</div>
            </div>
        </div>
    )
}