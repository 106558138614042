import React, { Component } from 'react';
import { history } from '../helpers/history';
import '../styles/customer/customerpage.scss';
import Header from '../components/Customer/Header';
import Warning from '../components/Customer/Warning';
import Contact from '../components/Customer/Contact';
import { Logbook } from '../components/Customer/Logbook';
import { ButtonBar } from '../components/Customer/ButtonBar';
import { Container, Col, Row, Button } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import NavigationBar from '../components/Navigation/navigationBar';
import { LogbookEntries } from '../components/Customer/LogbookEntries'

export default class CustomerPage extends Component {

    render() {
        return(
            <div>
                <NavigationBar/>
                <Container fluid>
                    <Container>
                        <div className={'customRow w-100'}>
                            <Header name={this.props.location.state.customer.searchable.name} />
                            <Button onClick={() => history.goBack()} className={'backButton'} variant={'link'}>
                                <FontAwesomeIcon  icon={'arrow-left'} size={'2x'} />
                            </Button>
                        </div>
                        <hr className={'divider'}/>
                        <div className={'margin'}>
                            <Row>
                                <Col>
                                    <Contact result={this.props.location.state.customer.searchable} />
                                </Col>
                                <Col>
                                    {/* <Warning /> */}
                                </Col>
                            </Row>
                        </div>
                        <hr className={'divider'}/>
                        <div className={'margin'}>
                            <Logbook/>
                            <ButtonBar />
                            <LogbookEntries />
                        </div>
                    </Container>
                </Container>
            </div>
        )
    }
}