import {
    FETCH_SEARCH_PENDING,
    FETCH_SEARCH_SUCCESS,
    FETCH_SEARCH_ERROR
} from './searchActions';

export const initialState = {
    results: [],
    loading: false,
    error: null
};

export default function searchReducer(state = initialState, action) {
    switch(action.type) {
        case FETCH_SEARCH_PENDING:
            return {
                ...state,
                loading: true
            }

        case FETCH_SEARCH_SUCCESS:
            return {
                ...state,
                loading: false,
                results: action.results
            }
        case FETCH_SEARCH_ERROR:
            return {
                ...state,
                results: [],
                loading: false,
                error: action.error
            }
        default:
            return state;
    }
}