import React, { Component } from "react";

import Map from "../components/map";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Sidebar from "../components/Sidebar/sideBar";
import Warning from "../components/Warnings/warning";
import FilterBar from "../components/Filter/filterBar";
import fetchWarningsAction from "../api/fetchWarnings";
import NavigationBar from "../components/Navigation/navigationBar";

class Dashboard extends Component {
  componentDidMount = () => {
    const { fetchWarnings } = this.props;
    fetchWarnings();
  };

  render() {
    return (
      <div>
        <Map />
        <NavigationBar />
        {this.props.stores.navigation.filter ? <FilterBar /> : null}
        {this.props.stores.containers.selectedContainer ? <Sidebar /> : null}
        {this.props.stores.warning.show ? <Warning /> : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  stores: state,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchWarnings: fetchWarningsAction,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard);

export { Dashboard };
