import { history } from '../../helpers/history';
import { getBaseURL, getAuthHeader, handleResponse } from '../ApiBase';
import { changePasswordPending, changePasswordSuccess, changePasswordError } from '../../redux/login/changePasswordActions';

const changePassword = (password, password_confirmation) => {
    const requestOptions = {
        method: 'POST',
        headers: getAuthHeader(),
        body: JSON.stringify({password, password_confirmation})
    }

    return dispatch => {
        dispatch(changePasswordPending())

        return fetch(`${getBaseURL()}/auth/change-password`, requestOptions)
            .then(handleResponse)
            .then(res => {
                console.log(res);

                dispatch(changePasswordSuccess(res))
                history.push('/dashboard');
            }
        )
        .catch(error => {
            dispatch(changePasswordError(error))
        })
    }
}

export default changePassword;