import {
  FILTER_OPTIONS,
  LOCATION,
  DESTINATION,
  CONTENT,
  WARNING,
  LOAD_MIN,
  LOAD_MAX,
} from "../constants/FilterOptions";

class FilterHelper {
  filterFeatures = (features, selectedFilters) => {
    let containers = features;

    containers = this.filterFeaturesByLocation(
      containers,
      selectedFilters[LOCATION]
    );
    containers = this.filterFeaturesByContent(
      containers,
      selectedFilters[CONTENT]
    );
    containers = this.filterFeaturesByDestination(
      containers,
      selectedFilters[DESTINATION]
    );
    containers = this.filterFeaturesByWarnings(
      containers,
      selectedFilters[WARNING]
    );
    containers = this.filterFeaturesByLoad(
      containers,
      selectedFilters[LOAD_MIN],
      selectedFilters[LOAD_MAX]
    );

    return containers;
  };


  filterFeaturesByLoad = (containerFeatures, minFilter, maxFilter) => {
    //return null if eiter argument is null
    if (!containerFeatures || !minFilter || !maxFilter) return [];

    // convert string values from json to numbers
    let min = Number(minFilter.value);
    let max = Number(maxFilter.value);

    //filter out the containers whose load percentage is not in between the given min and max
    return containerFeatures.filter(function (containerFeature) {
      let loadP = null;
      loadP = Number(containerFeature.properties.load_percentage)

      if (isNaN(loadP)) {
        loadP = 0;
      }

      return loadP >= min && loadP <= max;
    });
  };

  filterFeaturesByWarnings = (containerFeatures, selectedFilters) => {
    //return null if eiter argument is null
    if (!containerFeatures || !selectedFilters) return [];

    //jest cant compare objects, so we extract only the values and put them in an array
    let selectedOptions = selectedFilters.map((e) => e.value);

    //add both filter option for merchandise at customer, if conflicting locations is a selected option
    if (selectedOptions.includes("conflicting_location")) {
      selectedOptions.push("merch_at_customer");
    }

    //filter out the containers whose warning is included in the unselected options
    return containerFeatures.filter(function (containerFeature) {
      let shouldExclude = false;
      let warnings = containerFeature.properties.warnings;

      //true if container has no warnings and 'without' is selected as option
      if (
        Array.isArray(warnings) &&
        !warnings.length &&
        selectedOptions.includes("without")
      ) {
        shouldExclude = true;
        //true if a any single one warning is currently selected as an option
      } else if (warnings) {
        warnings.forEach((element) => {
          if (selectedOptions.includes(element.type)) {
            shouldExclude = true;
          }
        });
      }

      //filter?
      return shouldExclude;
    });
  };

  filterFeaturesByDestination = (containerFeatures, selectedFilters) => {
    //return null if eiter argument is null
    if (!containerFeatures || !selectedFilters) return [];

    //jest cant compare objects, so we extract only the values and put them in an array
    let options = FILTER_OPTIONS[DESTINATION].map((e) => e.value);
    let selectedOptions = selectedFilters.map((e) => e.value);

    //find values to filter by (which filteroptions are not selected?)
    let unselectedOptions = options.filter(function (filterOptions) {
      return !selectedOptions.includes(filterOptions);
    });

    //filter out the containers whose destination is included in the unselected options
    return containerFeatures.filter(function (containerFeature) {
      return (
        //add if within normal filters, or if entire place object is null en 'within' is selected as filter option
        containerFeature.properties.place
          ? !unselectedOptions.includes(containerFeature.properties.place.type)
          : selectedOptions.includes("without")
      );
    });
  };

  filterFeaturesByLocation = (containerFeatures, selectedFilters) => {
    //return null if eiter argument is null
    if (!containerFeatures || !selectedFilters) return [];

    //jest cant compare objects, so we extract only the values and put them in an array
    let options = FILTER_OPTIONS[LOCATION].map((e) => e.value);
    let selectedOptions = selectedFilters.map((e) => e.value);

    //find values to filter by (which filteroptions are not selected?)
    let unselectedOptions = options.filter(function (filterOptions) {
      return !selectedOptions.includes(filterOptions);
    });

    //filter out the containers whose status is included in the unselected options
    return containerFeatures.filter(function (containerFeature) {
      return !unselectedOptions.includes(containerFeature.properties.status);
    });
  };

  filterFeaturesByContent = (containerFeatures, selectedFilters) => {
    //return null if eiter argument is null
    if (!containerFeatures || !selectedFilters) return [];

    //jest cant compare objects, so we extract only the values and put them in an array
    let options = FILTER_OPTIONS[CONTENT].map((e) => e.value);
    let selectedOptions = selectedFilters.map((e) => e.value);

    //find values to filter by (which filteroptions are not selected?)
    let unselectedOptions = options.filter(function (filterOptions) {
      return !selectedOptions.includes(filterOptions);
    });

    //filter out the containers whose content is included in the unselected options
    return containerFeatures.filter(function (containerFeature) {
      return !unselectedOptions.includes(containerFeature.properties.type);
    });
  };

  getFiltersByWarningVisible = (warningVisible, warningFilterOptions) => {
    let warningFilterJSONObjects = warningFilterOptions;
    let warningFilterValues = warningFilterJSONObjects.map((e) => e.value);

    if (warningVisible) {
      warningFilterJSONObjects = warningFilterJSONObjects.filter(function (
        option
      ) {
        return option.value !== "without";
      });
    } else {
      //TODO ONLY if selected beforehand already
      if (!warningFilterValues.includes("without")) {
        warningFilterJSONObjects.push({
          value: "without",
          label: "Zonder waarschuwing",
        });
      }
    }

    return warningFilterJSONObjects;
  };
}

//for singleton pattern
const FILTER_HELPER_INSTANCE = new FilterHelper();
Object.freeze(FILTER_HELPER_INSTANCE);

export { FILTER_HELPER_INSTANCE };
