import React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import '../../styles/warning/minimizeButton.scss';
import { updateFilterSettings } from '../../redux/filter/filterActions';
import { toggleWarningDisplay } from '../../redux/warning/warningActions';
import { FILTER_HELPER_INSTANCE } from '../../helpers/filterHelper'
import { WARNING } from '../../constants/FilterOptions'

class MinimizeButton extends React.Component {

    handleMinimize = () => {
        const { toggleWarning, updateFilter, warning, filterValues } = this.props;

        let warningFilterValues = FILTER_HELPER_INSTANCE.getFiltersByWarningVisible(!warning.show, filterValues[WARNING]);
        updateFilter( warningFilterValues, WARNING );
        toggleWarning()   
    }

    render () {
        return (
            <button id="minimize" onClick={() => this.handleMinimize()} className='minimize-button'>
                <img className='minus' src={require('../../images/warning/minus.svg')} alt='minus'/>
            </button>
        )
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    toggleWarning: toggleWarningDisplay,
    updateFilter: updateFilterSettings 
}, dispatch)

const mapStateToProps = state => ({
    warning: state.warning,
    filterValues: state.filter
})

export default connect (
    mapStateToProps,
    mapDispatchToProps
)(MinimizeButton)

export { MinimizeButton }