export const CARGO_TYPE_VOCABULARY = {
    drycleaning: [
        'dry cleaning',
        'wasgoed'
    ],
    linnen: [
        'linnen',
        'linnen'
    ],
    merchandise: [
        'merchandise',
        'handel'
    ],
    uniforms: [
        'uniforms',
        'werkkleding'
    ]
}

export const CONTAINER_REACHED_VOCABULARY = {
    transit: [
        'in transit to',
        'is onderweg naar'
    ],
    arrived: [
        'has arrived at',
        'is aangekomen bij'
    ],
    no_place: [
        'No destination',
        'Geen bestemming'
    ]
}

export const CONTAINER_STATUS_VOCABULARY = {
    transit: [
        'in transit to',
        'is onderweg naar'
    ],
    arrived: [
        'has arrived at',
        'is aangekomen bij'
    ],
    at_hub: [
        'has arrived at',
        'is aangekomen bij'
    ],
    at_moderna: [
        'has arrived at',
        'is aangekomen bij'
    ],
    conflicting: [
        'Last seen near',
        'Laatst gezien in de buurt van'
    ],
    no_place: [
        'No destination',
        'Geen bestemming'
    ]
}

export const CONTAINER_CLUSTER_STATUS_VOCABULARY = {
    arrived: [
        'At customer',
        'Bij klant'
    ],
    transit: [
        'In transit',
        'Onderweg'
    ],
    at_hub: [
        'At hub',
        'Bij hub'
    ],
    at_moderna: [
        'At Moderna',
        'Bij Moderna',
    ],
    conflicting: [
        'Conflicting location',
        'Afwijkende locatie'
    ]
}

export const WARNING_TYPE_TITLE = {
    battery_low: [
        'Battery almost empty',
        'Batterij bijna leeg'
    ],
    no_signal: [
        'Container has lost its signal',
        'Container heeft geen signaal'
    ],
    conflicting_location: [
        'Container has an unexpected location',
        'Container heeft een afwijkende locatie'
    ],
    stay_duration_exceeded: [
        'Container is located at a customer for more than a month',
        'Container staat meer dan een maand bij een klant'
    ],
    merch_at_customer: [
        'Merchandise container is located at customer',
        'Handelscontainer staat bij klant'
    ]
}

export const WARNING_TYPE_MESSAGE = {
    battery_low: [
        ' has ',
        ' heeft nog '
    ],
    stay_duration_exceeded: [
        ' Is located for more than a month at ',
        ' staat al meer dan een maand bij '
    ],
    merch_at_customer: [
        ' is tagged for merchandise but is located at',
        ' is bedoeld voor handel maar staat bij '
    ],
    no_signal: [
        ' missed too many location updates. Last known location was at ',
        ' heeft te lang geen signaal gegeven. Laatst gezien bij '
    ],
    conflicting_location: [
        ' was last seen at ',
        ' stond bij '
    ]
}

export const WARNING_TYPE_EXTRA_MESSAGE = {
    battery_low: [
        '% batterycharge left. Make sure it is replaced before it runs out.',
        '% over in de batterij. Zorg dat deze op tijd vervangen wordt.'
    ],
    conflicting_location: [
        ' but has an unexpected location. ',
        ' maar heeft nu een afwijkende locatie. '
    ]
}