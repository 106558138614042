import React from 'react';
import { Button } from 'react-bootstrap';
import '../../styles/warning/exportButton.scss';

export const ExportButton = () => {
    return (
        <Button className='export-button text-uppercase' variant='secondary'>
            Export
            <img src={require('../../images/export-icon.svg')} alt='export'/>
        </Button>
    )
}