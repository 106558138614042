
//Imports for the cluster images shown on the map
import arrived_cluster_image from '../images/map/arrived_cluster.svg';
import transit_cluster_image from '../images/map/transit_cluster.svg';
import warning_cluster_image from '../images/map/warning_cluster.svg';
import moderna_cluster_image from '../images/map/moderna_cluster.svg';
import warning_red_cluster_image from '../images/map/warning_red_cluster.svg';
import hub_cluster_image from '../images/map/hub_cluster.svg';

//Match source to image object
//clusters
const arrivedCluster = new Image();
arrivedCluster.src= arrived_cluster_image;
const transitCluster = new Image();
transitCluster.src = transit_cluster_image
const warningCluster = new Image();
warningCluster.src = warning_cluster_image
const warningRedCluster = new Image();
warningRedCluster.src = warning_red_cluster_image
const modernaCluster = new Image();
modernaCluster.src = moderna_cluster_image
const hubCluster = new Image();
hubCluster.src = hub_cluster_image

//Export a const that can be used within different components
export const MARKER_IMAGES = [['arrivedCluster', arrivedCluster], ['transitCluster', transitCluster], ['warningCluster', warningCluster], ['warningRedCluster', warningRedCluster], ['hubCluster', hubCluster],['modernaCluster', modernaCluster]];