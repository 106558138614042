export const FETCH_PLACES_PENDING = 'FETCH_PLACES_PENDING';
export const FETCH_PLACES_SUCCESS = 'FETCH_PLACES_SUCCESS';
export const FETCH_PLACES_ERROR = 'FETCH_PLACES_ERROR';

export const fetchPlacesPending = () => {
    return {
        type: FETCH_PLACES_PENDING
    }
}

export const fetchPlacesSuccess = places => {
    return {
        type: FETCH_PLACES_SUCCESS,
        places: places
    }
}

export const fetchPlacesError = error => {
    return {
        type: FETCH_PLACES_ERROR,
        error: error
    }
}