import React from 'react';

const loadIcons = [
    require('../images/sidebar/Load-0.svg'),
    require('../images/sidebar/Load-25.svg'),
    require('../images/sidebar/Load-50.svg'),
    require('../images/sidebar/Load-75.svg'),
    require('../images/sidebar/Load-100.svg')
]

const batteryIcons = [
    require('../images/sidebar/Battery-0.svg'),
    require('../images/sidebar/Battery-25.svg'),
    require('../images/sidebar/Battery-50.svg'),
    require('../images/sidebar/Battery-75.svg'),
    require('../images/sidebar/Battery-100.svg')
]

const loadThresholds = [0, 1, 30, 60, 100];
const batteryThresholds = [0, 10, 30, 60, 90];

/**
 * This class can help to retrieve the correct icons to display
 */
class IconHelper {

    getLoadIcon = (loadPercentage) => {
        for (let i = loadThresholds.length -1; i >= 0 ; i--){
            if (loadPercentage >= loadThresholds[i]){
                return loadIcons[i]; 
            }                
        }
        return loadIcons[0];
    }

    getBatteryIcon = (batteryPercentage) => {
        for (let i = batteryThresholds.length -1; i >= 0 ; i--){
            if (batteryPercentage >= batteryThresholds[i]){
                return batteryIcons[i]; 
            }                
        }
        return loadIcons[0];
    }

}

//for singleton pattern
const ICON_HELPER = new IconHelper();
Object.freeze(ICON_HELPER);

export {ICON_HELPER}