import React, { useState } from 'react';
import '../../styles/customer/buttonbar.scss';
import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';


const ButtonBar = () => {

    return (
       <div className={'button-bar'}>
            <span>kies periode: </span> 
            <ToggleButtonGroup name='selected filter' type="radio" defaultValue={1}>
                <ToggleButton value={1} className={'button-logbook ml-4 mr-2'} variant={'outline-secondary'}>
                    altijd
                </ToggleButton>
                <ToggleButton value={2} className={'button-logbook mx-2'} variant={'outline-secondary'}>
                    deze week
                </ToggleButton>
                <ToggleButton value={3} className={'button-logbook mx-2'} variant={'outline-secondary'}>
                    deze maand
                </ToggleButton>
                <ToggleButton value={4} className={'button-logbook mx-2'} variant={'outline-secondary'}>
                    vorige maand
                </ToggleButton>
                <ToggleButton value={5} className={'button-logbook mx-2'} variant={'outline-secondary'}>
                    dit jaar
                </ToggleButton>
                <ToggleButton value={6} className={'button-logbook mx-2'} variant={'outline-secondary'}>
                    selecteer data
                </ToggleButton>
            </ToggleButtonGroup>
       </div>
    )
}

export { ButtonBar }