import { TOGGLE_WARNING_DISPLAY, FETCH_WARNINGS_PENDING, FETCH_WARNINGS_SUCCESS, FETCH_WARNINGS_ERROR } from './warningActions'

export const initialState = {
    warnings: [],
    loading: false,
    error: null,
    show: false
}

export default function warningReducer(state = initialState, action) {
    switch(action.type) {
        case FETCH_WARNINGS_PENDING:
            return {
                ...state,
                loading: true
            };
        case FETCH_WARNINGS_SUCCESS:
            return {
                ...state,
                loading: false,
                warnings: action.warnings
            };
        case FETCH_WARNINGS_ERROR: 
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case TOGGLE_WARNING_DISPLAY:
            return {
                ...state,
                show: !state.show
            };
        default:
            return state
    }
}