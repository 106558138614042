import React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { history } from '../../helpers/history';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import fetchSearchResults from '../../api/fetchSearchResults';

class SearchResults extends React.Component {

    _handleSearch = searchTerm => {
        this.props.search(searchTerm)
    }

    /**
     * Selected is returned as an array
     * even if there is a single item selected
     * which is always true in our case
     * 
     * Expansion: Once we can search for containers
     * we should check the type of result
     */
    _handleChange = selected => {
        let result = selected[0];

        if (result && result.type === 'containers') {
            let lat = selected[0].searchable.latest_device_history.locations[0].latitude;
            let lon = selected[0].searchable.latest_device_history.locations[0].longitude;

            history.push({
                pathname: '/dashboard',
                search: `?lat=${lat}&lon=${lon}`
            })

        } else if (result) {
            let id = selected[0].searchable.id;

            history.push({
                pathname: `/customer-page/${id}`,
                state: {
                    customer: result
                }
            })
        }
    }

    render() {
        return (
            <AsyncTypeahead
                delay={500}
                id={'search'}
                minLength={3}
                filterBy={(option, props) => {
                    return option
                }}
                highlightOnlyResult={true}
                options={this.props.results}
                onSearch={this._handleSearch}
                onChange={this._handleChange}
                isLoading={this.props.loading}
                promptText={'Typ om te zoeken'}
                inputProps={{ style: { width: 350 } }}
                searchText={'Zoeken naar resultaten'}
                emptyLabel={'Geen resultaten gevonden'}
                placeholder="Zoek op klant, hub of containernummer"
                labelKey={option => option.searchable.name ? `${option.searchable.name}` : `${option.searchable.serial_no}`} />
        )
    }
}

const mapStateToProps = state => ({
    loading: state.search.loading,
    results: state.search.results
})

const mapDispatchToProps = dispatch => bindActionCreators({
    search: fetchSearchResults
}, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SearchResults)