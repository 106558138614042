import { UPDATE_FILTER_SETTINGS } from './filterActions';
import { LOCATION, DESTINATION, WARNING, CONTENT, LOAD_MAX, LOAD_MIN, FILTER_OPTIONS} from '../../constants/FilterOptions'

export const initialState = {
    [LOCATION]: FILTER_OPTIONS[LOCATION],
    [DESTINATION]: FILTER_OPTIONS[DESTINATION],
    [WARNING]: FILTER_OPTIONS[WARNING],
    [CONTENT]: FILTER_OPTIONS[CONTENT],
    [LOAD_MIN]: FILTER_OPTIONS[LOAD_MIN],
    [LOAD_MAX]: FILTER_OPTIONS[LOAD_MAX],
}

export default function filterReducer(state = initialState, action) {
    switch(action.type) {
        case UPDATE_FILTER_SETTINGS:
            return {
                ...state,
                [action.filterType]: action.filterSettings
            };
        default: {
            return state
        }
    }
}