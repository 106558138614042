import React from 'react';
import Title from './Title';
import { Row } from "react-bootstrap";
import '../../styles/customer/contact.scss';
import { faAddressBook } from '@fortawesome/free-regular-svg-icons';

const Contact = ({result}) => {
    return (
       <div>
            <Title fontawesome={true} title={'Contactgegevens'} icon={faAddressBook} />
            <Row>
                <span className={'font-weight-bold padding'}>Adres:</span>
                <span className={'ml-1 paddingTop textColorGrey'}>{result.street}, {result.postal_code}, {result.city}</span>
            </Row>
            <Row>
                <span className={'font-weight-bold padding'}>Contactpersoon:</span>
                <span className={'ml-1 paddingTop textColorGrey'}>Jan Witte</span>
            </Row>
            <Row>
                <span className={'font-weight-bold padding'}>Telefoon:</span>
                <span className={'ml-1 paddingTop textColorGrey'}>0222 - 390 112</span>
            </Row>
            <Row>
                <span className={'font-weight-bold padding'}>Mail:</span>
                <span className={'ml-1 paddingTop textColorGrey'}>j.witte@krim.nl</span>
            </Row>
       </div>
    )
}

export default Contact