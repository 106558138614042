import React from 'react';
import '../../../styles/warning/warningDescriptions/clickableText.scss';
import { TRANSLATION_HELPER, DUTCH } from '../../../helpers/translationHelper';

export default class BatteryLowWarning extends React.Component {

    testOnClick = () => {
        console.log('test')
    }

    render () {
        return (
            <div className='message'>
                <span onClick={() => this.testOnClick()} className='text-secondary span-clickable'>
                    Container #{this.props.containerID} 
                </span> 
                {
                    TRANSLATION_HELPER.getTranslationForWarningMessage(this.props.warningType, DUTCH)
                }
                {
                    this.props.battery_level
                }
                {
                    TRANSLATION_HELPER.getTranslationForExtraMessage(this.props.warningType, DUTCH)
                }
            </div>
        )
    }
}