import React from 'react';
import { Button } from 'react-bootstrap';
import '../../styles/customer/exportbutton.scss';

export const ExportButton = () => {
    return (
        <Button className='button-export text-uppercase' variant="secondary">
            <div className='button-text'>
                Export
                <img src={require('../../images/sidebar/export-icon.svg')} alt='export' />
            </div>
        </Button>
    )
}