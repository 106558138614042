export const GEOJSON_SOURCE_OPTIONS = {cluster: true, clusterMaxZoom: 14, clusterRadius: 100}

export const LAYER_LAYOUT_OPTIONS = {
    "icon-allow-overlap": true, 
    "icon-anchor": "bottom", 
    'text-field': ["coalesce",["get","point_count"], "1"], 
    'text-font': ['DIN Offc Pro Medium', 
    'Arial Unicode MS Bold'],
    'text-size': 15,
    'text-allow-overlap': true,
    'text-ignore-placement': true
}

export const LAYER_IDENTIFIERS = [
    'conflicting_id-symbol',
    'arrived_id-symbol',
    'transit_id-symbol',
    'moderna_id-symbol',
    'hub_id-symbol',
]
