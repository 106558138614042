import React from 'react';
import { connect } from "react-redux";
import '../../styles/warningbutton.css';
import { bindActionCreators } from 'redux';
import { ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import { toggleWarningDisplay } from '../../redux/warning/warningActions';
import { deselectContainerAndCluster } from '../../redux/container/containerActions';
import { updateFilterSettings } from '../../redux/filter/filterActions';
import { FILTER_HELPER_INSTANCE } from '../../helpers/filterHelper'
import { WARNING } from '../../constants/FilterOptions'

class WarningButton extends React.Component {


    /**
     * Method that determines if we should show the warning component. It
     * also checks if it should hide the sidebar. The minimal required information
     * is that the warnings aren't showing already and either containes or containercluster
     * have a supported value.
     */
    handleChange = () => {
        const { toggleWarning, clearSidebar, containers, warning, updateFilter, filterValues } = this.props;

        if (!warning.show && (containers.selectedContainer || containers.selectedContainerCluster.length > 1)) {
            clearSidebar();
        }

        let warningFilterValues = FILTER_HELPER_INSTANCE.getFiltersByWarningVisible(!warning.show, filterValues[WARNING]);
        updateFilter( warningFilterValues, WARNING );
        toggleWarning();
    }

    render() {
        return (
            <ToggleButtonGroup className="mr-auto" type="checkbox">
                <ToggleButton onChange={() => this.handleChange()} className={this.props.warning.show ? 'ml-4 mr-4 toggle-button-active' : 'ml-4 mr-4 toggle-button'}>
                    <span className='left-part'>
                        <img src={require('../../images/warning.png')} alt='warning'/>
                    </span>
                    {   this.props.warning.warnings ?

                        <span>
                            {   this.props.warning.warnings.length === 1 ? 
                                    `${this.props.warning.warnings.length} Waarschuwing` 
                                
                                    : 
                                
                                    `${this.props.warning.warnings.length} Waarschuwingen`
                            } 
                        </span>

                        :

                        null
                    }
                </ToggleButton>
            </ToggleButtonGroup>
        )
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    toggleWarning: toggleWarningDisplay,
    clearSidebar: deselectContainerAndCluster,
    updateFilter: updateFilterSettings
}, dispatch)

const mapStateToProps = state => ({
    warning: state.warning,
    containers: state.containers,
    filterValues: state.filter
})

export default connect (
    mapStateToProps,
    mapDispatchToProps
)(WarningButton)