
class localStorageHelper {

    getFromLocalStorage = (key) => {
        const item = localStorage.getItem(key);

        if (item) {
            return JSON.parse(item)
        } else {
            return {};
        }
    }
}

//for singleton pattern
const LOCAL_STORAGE_HELPER = new localStorageHelper();
Object.freeze(LOCAL_STORAGE_HELPER);

export {LOCAL_STORAGE_HELPER}