import { 
    WARNING_TYPE_TITLE,
    WARNING_TYPE_MESSAGE,
    CARGO_TYPE_VOCABULARY,
    WARNING_TYPE_EXTRA_MESSAGE,
    CONTAINER_STATUS_VOCABULARY,
    CONTAINER_REACHED_VOCABULARY,
    CONTAINER_CLUSTER_STATUS_VOCABULARY,
} from '../constants/Translations';

export const ENGLISH = 0;
export const DUTCH = 1;

/**
 * This class can help to retrieve correct translations defined
 * in the constant class. Use 0 to retrieve English and use 1
 * to retrieve Dutch translations.
 */
class TranslationHelper {

    getTranslationForCargoType = (key, index) => {
        let cargoType =  CARGO_TYPE_VOCABULARY[key];
        if (!cargoType) {return key}

        return cargoType[index]
    }

    getTranslationForStatus = (key, index) => {
        let status = CONTAINER_STATUS_VOCABULARY[key]
        if (!status) {return key}

        return status[index]
    }

    getTranslationForReached = (key, index) => {
        let status = CONTAINER_REACHED_VOCABULARY[key]
        if (!status) {return key}

        return status[index]
    }

    getTranslationForClusterStatus = (key, index) => {
        let status = CONTAINER_CLUSTER_STATUS_VOCABULARY[key]
        if (!status) {return key}

        return status[index]
    }

    getTranslationForWarningTitle = (key, index) => {
        let warning = WARNING_TYPE_TITLE[key];
        if (!warning) {return key}

        return warning[index]
    }

    getTranslationForWarningMessage = (key, index) => {
        let warning = WARNING_TYPE_MESSAGE[key]
        if ( !warning ) { return key }

        return warning[index]
    }

    getTranslationForExtraMessage = (key, index) => {
        let warning = WARNING_TYPE_EXTRA_MESSAGE[key]
        if ( !warning ) { return key }

        return warning[index]
    }
}

//for singleton pattern
const TRANSLATION_HELPER = new TranslationHelper();
Object.freeze(TRANSLATION_HELPER);

export {TRANSLATION_HELPER}