import React from "react";
import "../../styles/sidebar/logbookInfo.scss";

export const LogbookInfo = () => {
  return (
    <div className="logbook-info">
      <div className="title flex-column">
        <h5 className="text-center">Logboek</h5>
      </div>
      <div className="logbook-status">
        <div className="logbook-title">Aangekomen bij Moderna</div>
        <div className="text-secondary logbook-title">
          Hoofdkantoor Hardenberg
        </div>
        <div className="text-muted logbook-title">
          2023-01-17 9:50 (Vandaag)
        </div>
      </div>
      <div className="logbook-connector">
        <img
          src={require("../../images/sidebar/logbook-connector.svg")}
          alt="connector"
        />
      </div>
      <div className="logbook-status">
        <div className="logbook-title">Vertrokken bij DEVcake</div>
        <div className="text-secondary logbook-title">Kantoor deventer</div>
        <div className="text-muted logbook-title">
          2023-01-17 08:51 (Vandaag)
        </div>
      </div>
    </div>
  );
};
