import React from 'react';
import '../../styles/alert.scss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Alert, Button } from "react-bootstrap";
import { clearAlert } from '../../redux/alert/alertActions';

function DefaultAlert (props) {
    return (
        props.alert.message ?
            <Alert className={'defaultAlert'} variant={props.alert.type}>
              <Alert.Heading>{props.alert.title}</Alert.Heading>
              <p className='flex-grow-1'>{props.alert.message}</p>
              <hr/>
              <div id="alert" className='d-flex justify-content-end ml-auto'>
                  <Button 
                    size={'sm'} 
                    onClick={() => props.clear()}
                    variant={`outline-${props.alert.type}`} >
                      Sluiten
                  </Button>
              </div>
            </Alert>
        :
  
        null
    )
}

const mapStateToProps = state => ({
    alert: state.alert
})

const mapDispatchToProps = dispatch => bindActionCreators({
    clear: clearAlert
}, dispatch)


export default connect (
    mapStateToProps,
    mapDispatchToProps
)(DefaultAlert)

export { DefaultAlert }