import moment from 'moment';
import localization from 'moment/locale/nl';
import { DUTCH_RELATIVE_TIME } from '../constants/RelativeTimeOptions';

class DateHelper {

    init = () => {
        moment.updateLocale('nl', DUTCH_RELATIVE_TIME)
        moment.updateLocale('nl', localization)
    }

    getDateToDisplay = date => {
        let dateToDisplay = moment(date);
        let threeDaysAgo = moment().subtract(3, 'd').startOf('day');

        if (dateToDisplay.isAfter(threeDaysAgo)) {
            return this.parseToRelativeTime(date);
        } else {
            return dateToDisplay.format('DD-MM-YYYY HH:MM')
        }
    }

    parseToRelativeTime = date => {
        let element = moment(date).fromNow();
        let time = this.getTimeFromDate(date);

        return element + time;
    }

    getTimeFromDate = date => {
        let time = moment(date);
        let yesterday = moment().subtract(1, 'd').endOf('day');
        let result = ''
        
        if (!time.isAfter(yesterday)) {
            result = ' om ' + moment(date).format('HH:mm').toString();
        }

        return result;
    }

}

//for singleton pattern
const DATE_HELPER = new DateHelper();
Object.freeze(DATE_HELPER);

export {DATE_HELPER}