import { fetchWarningsPending, fetchWarningsSuccess, fetchWarningsError } from '../redux/warning/warningActions';
import { getBaseURL } from '../api/ApiBase';

const fetchWarnings = () => {
    return dispatch => {
        dispatch(fetchWarningsPending());
        return fetch(`${getBaseURL()}/warnings/unresolved`)
        .then(res => res.json())
        .then(res => { if  (res.error) { throw(res.error); }
            dispatch(fetchWarningsSuccess(res));
        })
        .catch(error => {
            dispatch(fetchWarningsError(error));
        })
    }
}

export default fetchWarnings;