import React from 'react';
import Title from './Title';
import { Row } from "react-bootstrap";
import '../../styles/customer/logbook.scss';
import { ExportButton } from './ExportButton';
import { faHistory } from '@fortawesome/free-solid-svg-icons';

const Logbook = () => {
    return (
       <div className={'logbook'}>
            <div className={'mr-auto'}>
                <Title fontawesome={true} title={'Logboek'} icon={faHistory}/>
            </div>
            <ExportButton/>
       </div>
    )
}

export { Logbook }