import { USER } from '../../constants/LocalStorageOptions';
import { POST_LOGIN_PENDING, POST_LOGIN_SUCCESS, POST_LOGIN_ERROR } from './loginActions';

let user = JSON.parse(localStorage.getItem(USER));
export const initialState = user ? { loggedIn: true, user } : {loggedIn: false, loggingIn: false, message: null, user: null, error: null};

export default function loginReducer(state = initialState, action) {
    switch(action.type) {
        case POST_LOGIN_PENDING:
            return {
                ...state, 
                loggingIn: true
            };
        case POST_LOGIN_SUCCESS:
            return {
                ...state,
                loggedIn: true,
                loggingIn: false,
                user: action.user
            };
        case POST_LOGIN_ERROR:
            return {
                ...state,
                loggingIn: false,
                error: action.error
            };
        default:
            return state;
    }
}