export const CHANGE_PASSWORD_PENDING = 'LOGIN_PENDING';
export const CHANGE_PASSWORD_SUCCESS = 'LOGIN_SUCCESS';
export const CHANGE_PASSWORD_ERROR = 'LOGIN_ERROR';

export const changePasswordPending = () => {
    return {
        type: CHANGE_PASSWORD_PENDING
    }
}

export const changePasswordSuccess = message => {
    return {
        type: CHANGE_PASSWORD_SUCCESS,
        message: message
    }
}

export const changePasswordError = error => {
    return {
        type: CHANGE_PASSWORD_ERROR,
        error: error
    }
}