import { fetchSearchPending, fetchSearchSuccess, fetchSearchError } from '../redux/search/searchActions';
import { getBaseURL } from './ApiBase';

const fetchSearchResults = (search) => {
    return dispatch =>{
        dispatch(fetchSearchPending());
        return fetch(`${getBaseURL()}/search/${search}`)
        .then(res => res.json())
        .then(res => {
            if  (res.error) {
                throw(res.error);
            }

            dispatch(fetchSearchSuccess(res));
        })
        .catch(error => {
            dispatch(fetchSearchError(error));
        })
    }
}

export default fetchSearchResults;