import Pusher from "pusher-js";
import Echo from "laravel-echo";

window.Pusher = Pusher;

export const echo = new Echo({
  cluster: "eu",
  forceTLS: true,
  broadcaster: "pusher",
  key: "e25a635d7a59976cd548",
});
