
export const DUTCH_RELATIVE_TIME = {
    relativeTime: {
        future: "over %s",
        past:   "%s",
        s  : 'een paar seconden geleden',
        ss : '%d seconden geleden',
        m:  "één minuut geleden",
        mm: "%d minuten geleden",
        h:  "1 uur geleden",
        hh: "%d uur geleden",
        d:  "gisteren",
        dd: "%d dagen geleden",
        M:  "een maand geleden",
        MM: "%d maanden geleden",
        y:  "een jaar geleden",
        yy: "%d jaren geleden"
    }
}