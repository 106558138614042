import { connect } from "react-redux";
import React, { Component } from "react";
import { bindActionCreators } from 'redux'; 
import '../../styles/sidebar/clusterSidebar.scss';
import { MAP_CLICK_HELPER } from '../../helpers/mapClickHelper';
import {TRANSLATION_HELPER, DUTCH} from '../../helpers/translationHelper';
import { selectContainerInCluster } from '../../redux/container/containerActions';

class ContainerList extends Component {

    selectContainer = (container) => {
        this.props.setSelectedContainer(container);
        MAP_CLICK_HELPER.setSelectedContainerActive(container);
    }

    render() {
        return (
            <div className={'cluster-sidebar'}>
                {this.props.containers.map((container, i) => {
                    return (
                        <div key={i} data-id={container.properties.id} className={'container'}
                             onClick={() => this.selectContainer(container)}>
                            <div className={'title'}>
                                Container #{container.properties.id}
                            </div>
                            <div className={'location'}>
                                {TRANSLATION_HELPER.getTranslationForClusterStatus(container.properties.status, DUTCH)}
                            </div>
                            {container.properties.warnings.length > 0 ?

                                <div className={'warning'}>
                                    <img src={require('../../images/warning.png')} className={"warning-image"} alt='warning'/>
                                    <span className={'text'}>{container.properties.warnings.length} waarschuwing(en)</span>
                                </div>

                                : 

                                <div className={'warning'}>
                                    <img src={require('../../images/sidebar/check.svg')} className={"warning-image"} alt='warning'/>
                                    <span className={'text'}>Geen waarschuwingen</span>
                                </div>

                            }
                        </div>
                    )
                })}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    container: state.containers.selectedContainer,
})

const mapDispatchToProps = dispatch => bindActionCreators({
    setSelectedContainer: selectContainerInCluster
}, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ContainerList)

export { ContainerList }