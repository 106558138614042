export const UPDATE_CONTAINER_CLUSTER_SELECTED = 'UPDATE_CONTAINER_CLUSTER_SELECTED';
export const DESELECT_CONTAINER_AND_CLUSTER = 'DESELECT_CONTAINER_AND_CLUSTER';
export const SELECT_CONTAINER_IN_CLUSTER = 'SELECT_CONTAINER_IN_CLUSTER';
export const UPDATE_CONTAINER_SELECTED = 'UPDATE_CONTAINER_SELECTED';
export const FETCH_CONTAINERS_PENDING = 'FETCH_CONTAINERS_PENDING';
export const FETCH_CONTAINERS_SUCCESS = 'FETCH_CONTAINERS_SUCCESS';
export const FETCH_CONTAINERS_ERROR = 'FETCH_CONTAINERS_ERROR';

export const fetchContainersPending = () => {
    return {
        type: FETCH_CONTAINERS_PENDING
    }
}

export const fetchContainerSuccess = containers => {
    return {
        type: FETCH_CONTAINERS_SUCCESS,
        containers: containers
    }
}

export const fetchContainersError = error => {
    return {
        type: FETCH_CONTAINERS_ERROR,
        error: error
    }
}

export const updateContainerSelected = selectedContainer => {
    return {
        type: UPDATE_CONTAINER_SELECTED,
        container: selectedContainer
    }
}

export const updateContainerClusterSelected = selectedContainers => {
    return {
        type: UPDATE_CONTAINER_CLUSTER_SELECTED,
        containers: selectedContainers
    }
}

export const selectContainerInCluster = selectContainer => {
    return {
        type: SELECT_CONTAINER_IN_CLUSTER,
        container: selectContainer
    }
}

export const deselectContainerAndCluster = () => {
    return {
        type:  DESELECT_CONTAINER_AND_CLUSTER
    }
}