export const POST_LOGIN_PENDING = 'LOGIN_PENDING';
export const POST_LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const POST_LOGIN_ERROR = 'LOGIN_ERROR';

export const postLoginPending = () => {
    return {
        type: POST_LOGIN_PENDING
    }
}

export const postLoginSuccess = user => {
    return {
        type: POST_LOGIN_SUCCESS,
        user: user
    }
}

export const postLoginError = error => {
    return {
        type: POST_LOGIN_ERROR,
        error: error
    }
}