import { connect } from "react-redux";
import "mapbox-gl/dist/mapbox-gl.css";
import React, { Component } from "react";
import { Router } from "react-router-dom";
import { bindActionCreators } from "redux";
import { history } from "./helpers/history";
import { Switch, Route } from "react-router-dom";
import { DATE_HELPER } from "./helpers/dateHelper";
import DefaultAlert from "./components/Alerts/alert";
import { clearAlert } from "./redux/alert/alertActions";
import { PrivateRoute } from "./components/PrivateRoute";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCog,
  faFilter,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
/**
 * Imports for all required pages
 */
import SignIn from "./pages/Signin";
import Dashboard from "./pages/Dashboard";
import CustomerPage from "./pages/CustomerPage";
import ChangePassword from "./pages/ChangePassword";

library.add(faCog, faFilter, faArrowLeft);
class App extends Component {
  constructor(props) {
    super(props);

    history.listen((location, action) => {
      this.props.clear();
    });
  }

  componentDidMount() {
    DATE_HELPER.init();
  }

  render() {
    return (
      <div>
        <DefaultAlert />
        <Router history={history}>
          <Switch>
            <Route exact path="/" component={SignIn} />
            <PrivateRoute exact path="/dashboard" component={Dashboard} />
            <PrivateRoute path="/customer-page/:id" component={CustomerPage} />
            <PrivateRoute
              exact
              path="/change-password"
              component={ChangePassword}
            />
          </Switch>
        </Router>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clear: clearAlert,
    },
    dispatch
  );

export default connect(
  null,
  mapDispatchToProps
)(App);
