import { TOGGLE_FILTER_NAVIGATION } from './navigationActions'

export const initialState = {
    filter: false
}

export default function navigationReducer(state = initialState, action) {
    switch(action.type) {
        case TOGGLE_FILTER_NAVIGATION:
            return {
                filter: action.show
            };
        default: {
            return state
        }
    }
}