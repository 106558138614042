import { history } from '../../helpers/history';
import { USER } from '../../constants/LocalStorageOptions';
import { getBaseURL, getAuthHeader, handleResponse } from '../ApiBase';
import { postLoginPending, postLoginSuccess, postLoginError } from '../../redux/login/loginActions';

const postLogin = (email, password) => {
    const requestOptions = {
        method: 'POST',
        headers: getAuthHeader(),
        body: JSON.stringify({email, password})
    }

    return dispatch => {
        dispatch(postLoginPending());
        
        return fetch(`${getBaseURL()}/auth/login`, requestOptions)
            .then(handleResponse)
            .then(res => {
                localStorage.setItem(USER, JSON.stringify(res))
                dispatch(postLoginSuccess(res));

                if (res.user.force_password_reset) {
                    history.push('/change-password');
                } else {
                    history.push('/dashboard');
                }
            }
        )
        .catch(error => {
            dispatch(postLoginError(error));
        })
    }
}

export default postLogin;