import { LAYER_IDENTIFIERS } from '../constants/MapBoxOptions';

class MapClickHelper {

    getFeaturesForSymbol = async options => {
        const features = this.queryRenderedFeatures(options)
        const feature = features[0];
        
        if (feature.properties.cluster) {
            return await this.getClusterLeaves(feature, options)
        } else {
            return feature
        }
    }

    queryRenderedFeatures = (options) => {
        return options.map.queryRenderedFeatures(options.point, {
            layers: LAYER_IDENTIFIERS
        })
    }

    getClusterLeaves = async (feature, options) => {   
        const promise = new Promise((res, rej) => {
            options.map.getSource(feature.source).getClusterLeaves(feature.properties.cluster_id, Infinity, 0, (error, features) => {
                if (error) {
                    rej(error)
                    throw error;
                }

                res(features);
            })
        });

        return await promise
    }

    setSelectedContainerActive = (container) => {
        if (!container) {return;}

        let selectedContainerDiv = document.querySelector('[data-id="' + container.properties.id +'"]')
        let containerDivs = document.querySelectorAll('[data-id]')
        containerDivs.forEach((value) => {
            value.classList.remove('container__active');
        })

        if (selectedContainerDiv) {
            selectedContainerDiv.classList.add('container__active');
        }
    }
}

//for singleton pattern
const MAP_CLICK_HELPER = new MapClickHelper();
Object.freeze(MAP_CLICK_HELPER);

export {MAP_CLICK_HELPER}