import '../../styles/filterbar.css';
import { connect } from "react-redux";
import React, {Component} from "react";
import { bindActionCreators } from 'redux';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import { updateFilterSettings } from '../../redux/filter/filterActions';

class FilterMultiSelect extends Component {

    handleChange = (selectedOption) => {
        let { updateFilter } = this.props;
        updateFilter( selectedOption, this.props.type );
    };

    render() {
        return (            
            <ReactMultiSelectCheckboxes
                width={'100%'} 
                options={this.props.data}
                placeholderButtonLabel={this.props.placeholder}
                value={this.props.currentValue[this.props.type]}
                onChange={this.handleChange}
                hideSearch={true}
            />
        )
    }
}

const mapStateToProps = state => ({
    currentValue: state.filter,
})

const mapDispatchToProps = dispatch => bindActionCreators({
    updateFilter: updateFilterSettings
}, dispatch)

export default connect (
    mapStateToProps,
    mapDispatchToProps
)(FilterMultiSelect)

export {FilterMultiSelect};